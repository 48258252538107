var from = require('array.from');

const body = document.querySelector('body');
const handleToggle = () => body.classList.toggle('nav-active');


const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
        if (mutation.addedNodes.length) {
            const hamburgers = document.getElementsByClassName("hamburger");
            from(hamburgers).forEach(v => {
                if (!v.hasAttribute('listener-attached')) {
                    v.addEventListener('click', handleToggle);
                    v.setAttribute('listener-attached', 'true');
                }
            });
        }
    });
});

observer.observe(document.body, {
    childList: true,
    subtree: true
});

